<template>
  <div class="flex flew-row items-center rn-form-item" :class="className">
    <div class="flex-auto pr-1">
      <div class="text-md font-semibold capitalize text-secondary-700">
        {{ label }}<span v-if="required">*</span>
      </div>
      <div class="text-xs font-medium text-secondary-500">
        <slot />
      </div>
    </div>
    <div v-if="$slots.right" class="pl-1 flex-none leading-0">
      <slot name="right" />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapClassName } from '@/utils/classNames'

export default defineComponent({
  props: {
    class: {
      type: String,
      default: () => '',
    },
    label: {
      type: String,
      default: () => '',
    },
    required: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    ...mapClassName(),
  },
})
</script>

<style scoped>
.rn-form-item:not(:last-child) {
  padding-bottom: 1rem;
}

.rn-form-item:last-child {
  padding-bottom: 0rem;
}
</style>
