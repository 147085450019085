<template>
  <authenticated-page>
    <section-list class="font-montserrat text-secondary-700">
      <page-header @back="back">
        {{ $t('title.Preferences') }}
      </page-header>
      <section-item class="px-10">
        <form-item :label="$t('preferences.avatar')" class="pb-7">
          {{ $t('preferences.avatarDescription') }}
          <template v-slot:right>
            <avatar />
          </template>
        </form-item>
        <form-item :label="$t('preferences.firstName')" class="pb-7">
          {{ currentUser?.firstName }}
        </form-item>
        <form-item :label="$t('preferences.lastName')" class="pb-7">
          {{ currentUser?.lastName }}
        </form-item>
        <form-item :label="$t('preferences.email')">
          {{ currentUser?.email }}
        </form-item>
      </section-item>
      <section-item class="px-10">
        <form-item :label="$t('lang.language')">
          <template v-slot:right>
            <div class="w-32"><language-selector size="small" /></div>
          </template>
        </form-item>
      </section-item>
      <section-item class="px-10">
        <form-item
          :label="$t('diagnostic.title')"
          @click="doingDiagnostic = true"
        >
          {{ $t('diagnostic.description') }}
          <template v-slot:right>
            <icon :icon="diagnosticIcon" size="medium" />
          </template>
        </form-item>
      </section-item>
      <section-item class="px-10">
        <form-item
          :label="$t('global.offline.title')"
          @click="discardAllOfflineData"
        >
          <div class="flex-1">
            <div class="mb-0">{{ $t('global.offline.clearAllData') }}</div>
            <div v-if="storageUsage != null">
              {{
                $t('global.offline.estimatedSize', {
                  sizeInMegaBytes: storageUsage,
                  layerCount: offlineLayerCount,
                })
              }}
            </div>
          </div>
          <template v-slot:right>
            <icon :icon="cloudOfflineOutline" size="medium" />
          </template>
        </form-item>
      </section-item>
      <section-item class="px-10">
        <form-item :label="$t('global.logout')" @click="logout">
          {{ emailAuthenticated }}
          <template v-slot:right>
            <icon :icon="logoutIcon" size="medium" />
          </template>
        </form-item>
      </section-item>
    </section-list>
  </authenticated-page>
</template>

<script>
import { defineComponent } from 'vue'
import { mapActions, mapGetters } from 'vuex'

import AuthenticatedPage from '@/components/templates/AuthenticatedPage'
import LanguageSelector from '@/components/organisms/LanguageSelector'
import Avatar from '@/components/atoms/Avatar'
import PageHeader from '@/components/molecules/PageHeader'
import SectionList from '@/components/atoms/SectionList'
import SectionItem from '@/components/atoms/SectionItem'
import FormItem from '@/components/atoms/FormItem'
import Icon from '@/components/atoms/Icon'
import { diagnosticIcon, logoutIcon } from '@/assets/icons'

import { back } from '@/plugins/router'
import { cloudOfflineOutline } from 'ionicons/icons'

export default defineComponent({
  name: 'PreferencesPage',
  components: {
    AuthenticatedPage,
    LanguageSelector,
    PageHeader,
    SectionList,
    SectionItem,
    Icon,
    Avatar,
    FormItem,
  },
  setup() {
    return {
      logoutIcon,
      diagnosticIcon,
      cloudOfflineOutline,
    }
  },
  data() {
    return { pollStorageInfo: 0 }
  },
  mounted() {
    this.pollStorageInfo = setInterval(
      () => this.$store.dispatch('updateStorageInfo'),
      750
    )
  },
  unmounted() {
    clearInterval(this.pollStorageInfo)
  },
  methods: {
    back() {
      back('projectList')
    },
    ...mapActions(['logout', 'discardAllOfflineData']),
  },
  computed: {
    offlineLayerCount() {
      return this.$store.getters.countAtLeastPartiallyOfflineLayers
    },
    storageUsage() {
      return this.$store.getters.storageUsageInMegaBytes
    },
    ...mapGetters(['emailAuthenticated', 'currentUser']),
  },
})
</script>
