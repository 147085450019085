<template>
  <div class="w-12 h-12">
    <img
      :src="src || avatar"
      alt="User's profile picture"
      class="w-12 h-12 rounded-full"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'

export default defineComponent({
  name: 'Avatar',
  props: {
    src: {
      type: String,
      default: () => '',
    },
  },
  computed: {
    ...mapGetters(['avatar']),
  },
})
</script>
